.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

.footer small {
  font-size: 1rem;
  line-height: 24px;
  font-weight: 400;
}

.footer .contato p {
  text-align: right;
}

@media (max-width: 800px) {
  .footer small,
  .footer .contato {
    padding-bottom: 1.2rem;
  }
}

@media (max-width: 630px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer .contato p {
    text-align: center;
  }
}
