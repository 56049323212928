@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
.Carousel_content__15sos {
  display: flex;
  transition: transform 0.3s ease;
}
.Carousel_carouselWrapper__3s4QI {
  overflow: hidden;
  position: relative;
  padding-top: 79px;
}
.Carousel_carouselWrapper__3s4QI img {
  flex-shrink: 0;
}

.Carousel_ball__2FR8t {
  text-indent: -1000000px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  border: none;
}
.Carousel_ball__2FR8t:nth-child(n + 2) {
  margin-left: 0.75rem;
}
.Carousel_nav__1bu79 {
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  bottom: 32%;
  left: 50%;
}

.Carousel_ball__2FR8t.Carousel_active__1efNx {
  background-color: #ff3c09;
}

.Carousel_carouselWrapper__3s4QI p {
  position: absolute;
  bottom: 35%;
  transform: translateX(-50%) translateY(-50%);

  left: 50%;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.3125rem;
  max-width: 348px;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 960px) {
  .Carousel_nav__1bu79 {
    bottom: 15%;
  }
  .Carousel_carouselWrapper__3s4QI p {
    bottom: 20%;
  }
}

@media (max-width: 700px) {
  .Carousel_carouselWrapper__3s4QI p {
    font-size: 1rem;
    width: 100%;
  }
  .Carousel_carouselWrapper__3s4QI {
    padding-top: 50px;
  }
}

.Banner_banner__1EtbD {
  margin: 60px 0 60px 0;
  display: block;
}

@media (max-width: 700px) {
  .Banner_banner__1EtbD {
    margin: 30px 0 30px 0;
  }
}

.PhotoGrid_photoContent__1E2jS {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
}

.PhotoGrid_mainContainer__32wv- {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PhotoGrid_button__3bwKh {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 24px;
  border-radius: 4px;
  border: none;
  background-color: #ff3c09;
  color: #fff;
  cursor: pointer;
  outline: none;
  padding: 11px 16px;
  font-weight: 400;
  margin: 2.6875rem auto 0 auto;
}

.PhotoGrid_button__3bwKh.PhotoGrid_desabilitado__3R7Jp {
  background-color: #c3c3c3;
  cursor: default;
}

@media (max-width: 700px) {
  .PhotoGrid_photoContent__1E2jS {
    grid-template-columns: 1fr;
  }
}

.Sobre_title__3hVNx {
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  color: #ff3c09;
  margin-bottom: 24px;
  margin-top: 20px;
}

.Sobre_description__33QL0 {
  max-width: 894px;

  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-bottom: 7.5rem;
}

@media (max-width: 800px) {
  .Sobre_title__3hVNx {
    font-size: 1.5rem;
  }
  .Sobre_description__33QL0 {
    font-size: 1rem;
  }
}

.Footer_footer__3kRR3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

.Footer_footer__3kRR3 small {
  font-size: 1rem;
  line-height: 24px;
  font-weight: 400;
}

.Footer_footer__3kRR3 .Footer_contato__20JcM p {
  text-align: right;
}

@media (max-width: 800px) {
  .Footer_footer__3kRR3 small,
  .Footer_footer__3kRR3 .Footer_contato__20JcM {
    padding-bottom: 1.2rem;
  }
}

@media (max-width: 630px) {
  .Footer_footer__3kRR3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Footer_footer__3kRR3 .Footer_contato__20JcM p {
    text-align: center;
  }
}

.Header_header__1CGKd {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  margin-top: 0;
  z-index: 1000;
}

.Header_headerContainer__1P9Gl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Header_nav__15M3N {
  flex: 1 1;
}
.Header_nav__15M3N ul {
  display: flex;
  margin-left: 6.75rem;
  align-items: center;
}
.Header_nav__15M3N ul li {
  color: #ff3c09;
}

.Header_sobre__su-mp {
  margin-left: 5.46875rem;
}
.Header_instagram__2JTUD > svg {
  margin-right: 0.75rem;
}
.Header_instagram__2JTUD {
  display: flex;
  align-items: center;
}

.Header_headerContainer__1P9Gl a {
  font-size: 1rem;
  line-height: 24px;
}

.Header_mobileButton__aEUBS {
  background-color: white;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.1s;
  padding: 0px;
  cursor: pointer;
}
.Header_mobileButton__aEUBS::after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 2px;
  background-color: currentColor;
  border-radius: 2px;
  box-shadow: 0 6px currentColor, 0 -6px currentColor;
  transition: 0.4s;
}

.Header_mobileButton__aEUBS:focus,
.Header_mobileButton__aEUBS:hover,
.Header_mobileButtonActive__2thUM {
  outline: none;
  background: white;

  border-color: #ff3c09;
  color: #ff3c09;
}
.Header_mobileButtonActive__2thUM::after {
  transform: rotate(-90deg);
  width: 4px;
  height: 4px;
  box-shadow: 0 8px currentColor, 0 -8px currentColor;
}

.Header_navMobile__1xydE {
  display: block;
  position: absolute;
  top: 60px;
  right: 17px;
  padding: 0 1rem;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transform: translateX(-10px);
  opacity: 0;
  pointer-events: none;
}
.Header_navMobileActive__2Asuq {
  transition: 0.3s;
  background-color: white;
  transform: initial;
  pointer-events: initial;
  opacity: 1;
  z-index: 200000;
}
.Header_navMobile__1xydE ul li {
  color: #ff3c09;
}
.Header_navMobile__1xydE ul {
  padding: 1rem;
}
@media (max-width: 840px) {
  .Header_nav__15M3N ul {
    margin-left: 0;
  }
  .Header_header__1CGKd {
    padding: 0.5rem 0 0.5rem 0;
  }
  .Header_sobre__su-mp {
    margin-left: 1.7rem;
  }
  .Header_instagram__2JTUD {
    display: none;
  }
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
a {
  text-decoration: none;
  color: #333;
}
img {
  display: block;
  max-width: 100%;
}
body {
  margin: 0;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}
.container {
  max-width: 1128px;
  margin: 0 auto;
  padding: 0.5rem 0;
}

/* Para o carousel */
.item {
  flex-shrink: 0;
  width: 100%;
  position: relative;
}

.item img {
  width: 100%;
  height: 617.797px;
}
.loading{
  width: 100%;
  display: block;
  height: 226px;
    display: flex;
    align-items: center;
}
@media (max-width: 1128px) {
  .container {
    padding: 0 1rem;
  }
}

@media (max-width: 830px) {
  .item img {
    height: 300px;
  }
}

