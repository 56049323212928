.photoContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 24px;
  border-radius: 4px;
  border: none;
  background-color: #ff3c09;
  color: #fff;
  cursor: pointer;
  outline: none;
  padding: 11px 16px;
  font-weight: 400;
  margin: 2.6875rem auto 0 auto;
}

.button.desabilitado {
  background-color: #c3c3c3;
  cursor: default;
}

@media (max-width: 700px) {
  .photoContent {
    grid-template-columns: 1fr;
  }
}
