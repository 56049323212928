.content {
  display: flex;
  transition: transform 0.3s ease;
}
.carouselWrapper {
  overflow: hidden;
  position: relative;
  padding-top: 79px;
}
.carouselWrapper img {
  flex-shrink: 0;
}

.ball {
  text-indent: -1000000px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  border: none;
}
.ball:nth-child(n + 2) {
  margin-left: 0.75rem;
}
.nav {
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  bottom: 32%;
  left: 50%;
}

.ball.active {
  background-color: #ff3c09;
}

.carouselWrapper p {
  position: absolute;
  bottom: 35%;
  transform: translateX(-50%) translateY(-50%);

  left: 50%;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.3125rem;
  max-width: 348px;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 960px) {
  .nav {
    bottom: 15%;
  }
  .carouselWrapper p {
    bottom: 20%;
  }
}

@media (max-width: 700px) {
  .carouselWrapper p {
    font-size: 1rem;
    width: 100%;
  }
  .carouselWrapper {
    padding-top: 50px;
  }
}
