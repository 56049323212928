.title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  color: #ff3c09;
  margin-bottom: 24px;
  margin-top: 20px;
}

.description {
  max-width: 894px;

  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-bottom: 7.5rem;
}

@media (max-width: 800px) {
  .title {
    font-size: 1.5rem;
  }
  .description {
    font-size: 1rem;
  }
}
