.banner {
  margin: 60px 0 60px 0;
  display: block;
}

@media (max-width: 700px) {
  .banner {
    margin: 30px 0 30px 0;
  }
}
