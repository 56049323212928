.header {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  margin-top: 0;
  z-index: 1000;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav {
  flex: 1;
}
.nav ul {
  display: flex;
  margin-left: 6.75rem;
  align-items: center;
}
.nav ul li {
  color: #ff3c09;
}

.sobre {
  margin-left: 5.46875rem;
}
.instagram > svg {
  margin-right: 0.75rem;
}
.instagram {
  display: flex;
  align-items: center;
}

.headerContainer a {
  font-size: 1rem;
  line-height: 24px;
}

.mobileButton {
  background-color: white;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.1s;
  padding: 0px;
  cursor: pointer;
}
.mobileButton::after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 2px;
  background-color: currentColor;
  border-radius: 2px;
  box-shadow: 0 6px currentColor, 0 -6px currentColor;
  transition: 0.4s;
}

.mobileButton:focus,
.mobileButton:hover,
.mobileButtonActive {
  outline: none;
  background: white;

  border-color: #ff3c09;
  color: #ff3c09;
}
.mobileButtonActive::after {
  transform: rotate(-90deg);
  width: 4px;
  height: 4px;
  box-shadow: 0 8px currentColor, 0 -8px currentColor;
}

.navMobile {
  display: block;
  position: absolute;
  top: 60px;
  right: 17px;
  padding: 0 1rem;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transform: translateX(-10px);
  opacity: 0;
  pointer-events: none;
}
.navMobileActive {
  transition: 0.3s;
  background-color: white;
  transform: initial;
  pointer-events: initial;
  opacity: 1;
  z-index: 200000;
}
.navMobile ul li {
  color: #ff3c09;
}
.navMobile ul {
  padding: 1rem;
}
@media (max-width: 840px) {
  .nav ul {
    margin-left: 0;
  }
  .header {
    padding: 0.5rem 0 0.5rem 0;
  }
  .sobre {
    margin-left: 1.7rem;
  }
  .instagram {
    display: none;
  }
}
