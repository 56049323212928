@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
a {
  text-decoration: none;
  color: #333;
}
img {
  display: block;
  max-width: 100%;
}
body {
  margin: 0;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}
.container {
  max-width: 1128px;
  margin: 0 auto;
  padding: 0.5rem 0;
}

/* Para o carousel */
.item {
  flex-shrink: 0;
  width: 100%;
  position: relative;
}

.item img {
  width: 100%;
  height: 617.797px;
}
.loading{
  width: 100%;
  display: block;
  height: 226px;
    display: flex;
    align-items: center;
}
@media (max-width: 1128px) {
  .container {
    padding: 0 1rem;
  }
}

@media (max-width: 830px) {
  .item img {
    height: 300px;
  }
}
